import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { fadeInUpAnimation } from "../../_animation/fade-in-up.animation";

import { AppConfigService } from "../../_services/app-config.service";
import {
  ActivatedRoute,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from "@angular/router";
import { StorageService } from "../../_services/storage.service";
declare var $: any;
@Component({
  selector: "app-orderstatus",
  templateUrl: "./orderstatus.component.html",
  styleUrls: ["./orderstatus.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: [fadeInUpAnimation],
  host: {
    class: "container-fluid",
  },
})
export class OrderstatusComponent implements OnInit {
  apiurl = AppConfigService.settings.apiServer.apiurl;
  ClinicName: string;
  ClinicData: object[] = [];
  ClinicImageLogo: string = "";
  isMobile;
  AppData = {};
  PaymentMessage = "";
  PaymentStatus = "";
 
  months: object = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

 
  constructor(
   
    private route: ActivatedRoute,
    private http: HttpClient,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    
    
    const listdata = this.route.snapshot.data["status"];
   
    // var locationdata=this.storageService.secureStorage.getItem("locationSelected");
    // var map=locationdata["ShortName"];
//     if (this.route["queryParams"]["map"]) {
// map=this.route["queryParams"]["map"];
//     }
    // this.storageService.secureStorage.removeItem("selected_date");
    //     this.storageService.secureStorage.removeItem("timeselected");
    //     this.storageService.secureStorage.removeItem("AppointmentType");
    //     this.storageService.secureStorage.removeItem("initialdata");
    //     this.storageService.secureStorage.removeItem("service");
    //     this.storageService.secureStorage.removeItem("SelectedPractitioner");
    //     this.storageService.secureStorage.removeItem("patientType");
    //     this.storageService.secureStorage.removeItem("locationSelected");
   if (listdata["appdata"]) {
    this.PaymentStatus=listdata["status"];
      listdata["appdata"].subscribe((resp) => {
      this.AppData=resp;
      });
    }

    if (listdata["appdatas"]) {
      this.PaymentStatus=listdata["status"];
      var appData=listdata["appdatas"];
      appData.then((resp)=>{
        this.AppData=resp;
      })
      }
  }
  dateFormate(cdate) {
    
    var locadate = new Date(cdate);

    return (
      "<b>" +
      locadate.getDate() +
      " " +
      this.months[locadate.getMonth()] +
      "</b>, " +
      locadate.getFullYear()
    );
  }

  timeFormate(stime) {
    let m;
    const regex = /(AM|PM)/;
    var newtimeFormate;
    if ((m = regex.exec(stime)) !== null) {
      m.forEach((match, groupIndex) => {
        newtimeFormate = stime.replace(
          regex,
          '<span class="text-warning">' + match + "</span>"
        );
      });
    }

    return newtimeFormate;
  }
}
