import { Injectable } from "@angular/core";
import {
  Router,
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from "@angular/router";
import { BehaviorSubject, Observable, of, EMPTY, from, Subject } from "rxjs";
import { mergeMap, take, catchError, map } from "rxjs/operators";
import xml2js from "xml2js";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { ClinicService } from "../_services/clinic.service";
import { AppConfigService } from "../_services/app-config.service";
import { StorageService } from "../_services/storage.service";
@Injectable({
  providedIn: "root",
})
export class OrderstatusR implements Resolve<any> {
  initclinik = AppConfigService.settings.clinic.name;
  initpk = AppConfigService.settings.clinic.pk;
  constructor(
    private router: Router,
    private http: HttpClient,
    private cs: ClinicService,
    private storageService: StorageService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var repdata = {};
 if (route["queryParams"]["payment_status"]) {
      if (route["queryParams"]["payment_status"] == "Credit") {
        var Appdata={};
        //console.log(route["queryParams"]);
       
            repdata["status"] = "Success";
   repdata["appdatas"] =   this.cs.updateingleApp(
    route["queryParams"]["pk"],
    route["queryParams"]["payment_id"],
    route["queryParams"]["payment_request_id"]
    );
   return repdata;
      }

    }
   repdata["status"] = "Failed";
   repdata["appdata"] =  this.cs.getsingleApp(route["queryParams"]["pk"]);
   return repdata;

    
  }
}
