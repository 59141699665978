<header class="py-2   bg-white">
  <div class="container">
    <div class="d-flex flex-md-row flex-column justify-content-center align-items-center">
      <div class="px-md-3 px-1 text-center mb-2 mb-md-0 d-flex justify-content-center align-items-center">
        <img src="./assets/sssbk.png" class="img-fluid" alt="Getback logo"  style="max-height: 50px;">		
        <img src="./assets/gls-sm.png" class="img-fluid" alt="Dr. Sri Lakshmi Srinivas Guruji"  style="max-height: 50px;">		
      					</div>
      <div class="px-3 text-wrap text-center">
        <h5 class="fs-5" [innerHTML]="mainTitle">Dr. Sri Lakshmi Srinivas Guruji Appointment Booking</h5>
      </div>
    </div>
    <div class="text-center mt-3">
      <p class=" text-danger" [innerHTML]="AvalMessage"></p>
    </div>

  </div>
</header>
<router-outlet   ></router-outlet>

<footer class="">
    <div class="container">
    <div class="row">
      <div class="col-12 col-md-5 text-center text-md-start mb-3 mb-md-0">
      </div>       
    </div>
    <div class="row justify-content-between align-items-center  mt-2 mt-md-5 ">
      <div class="col-md-4 mb-3 mb-md-0"><p class=" text-muted fs-16   text-center text-md-start ">Copyright &copy; {{CurrentYear}} | <a href="mailto:info@lakshmisrinivas.in" target="_blank">info@lakshmisrinivas.in</a> </p></div>
      <div class="col-md-8 mb-3 mb-md-0 text-md-end text-center fs-13">
<a href="https://lakshmisrinivas.in/about-guruji.html" target="_blank">About Guruji</a> |
<a href="https://lakshmisrinivas.in/workshops.html" target="_blank">Guruji Workshops</a> |
<a href="https://lakshmisrinivas.in/terms-conditions.html" target="_blank">Terms & Conditions</a> |
<a href="https://lakshmisrinivas.in/terms-conditions.html" target="_blank">Refund & Cancellation</a> |
<a href="https://lakshmisrinivas.in/privacy-policy.html" target="_blank">Privacy Policy</a> 
 
      </div>
        
     
     
    </div>
</div>
  </footer>