import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IAppConfig } from '../_helper/appconfig';
@Injectable({
    providedIn: 'root'
})
export class AppConfigService {
    static settings: IAppConfig;

    constructor(private http: HttpClient) { }

    load() {
        const jsonFile = 'assets/config/config.json?t=' + Date.now();

        return new Promise<void>((resolve, reject) => {
            this.http.get(jsonFile, { headers: { skip: "true" } }).toPromise().then((response: IAppConfig) => {
                AppConfigService.settings = <IAppConfig>response;
                resolve();

            }).catch((response: any) => {
                reject(`Could not load the config file`);
            });
        });
    }
}