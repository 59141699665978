<div class="container">
    <div class="row justify-content-center">
        <div class="col-md-5">
          
            <h4 [innerHTML]="leadFormTitle" *ngIf="leadFormTitle!=''"></h4>
            <div [innerHTML]="leadFormMessage" *ngIf="leadFormMessage!=''" class="mb-3"></div>
            <div class="card shadow-sm">
                <div class="card-body">
                    <form [formGroup]="leadform" (submit)="submitform($event)">
                        <div class="form-group mb-3">
                <input type="text" class="form-control" placeholder="First Name"  [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }"  formControlName="first_name" />
                        </div>
                        <div class="form-group mb-3">
                            <input type="tel" class="form-control"  placeholder="Phone" formControlName="phone_number" [ngClass]="{ 'is-invalid': submitted && f.phone_number.errors }"  />
                
                        </div>
                        <div class="form-group mb-3">
                            <input type="email" class="form-control" placeholder="Email Address"  formControlName="email"  [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                
                
                        </div>
                        <div class="form-group mb-3  ">
                            
                                <button class="btn btn-lg btn-primary w-100" type="submit" [disabled]="ispreloader" [ngClass]="{'btn-invalid':validationAnimate && leadform.invalid,'btn-primary':!isSuccess,'btn-success  lh-1':isSuccess}"><span *ngIf="!ispreloader && !isSuccess">Submit</span><span *ngIf="ispreloader"><i class="fa-solid fa-circle-notch fa-spin" ></i></span><span *ngIf="isSuccess"><i class="bi bi-check-lg fs-28 btn-valid fs-18" [ngClass]="{'btn-valid':isSuccess}"></i></span></button>
                             
                        </div>
                        
                
                
                    </form>
</div>
</div>
</div>
</div>
</div>

<!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header border-0">
          <h5 class="modal-title" id="staticBackdropLabel"></h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <h4 class="mb-3 text-success text-center">Success</h4>
            <div class="success-checkmark">
                <div class="check-icon">
                  <span class="icon-line line-tip"></span>
                  <span class="icon-line line-long"></span>
                  <div class="icon-circle"></div>
                  <div class="icon-fix"></div>
                </div>
              </div>
              <div [innerHTML]="leadFormSuccesMessage"></div>
              
        </div>
        
      </div>
    </div>
  </div>
