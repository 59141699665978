import { Injectable } from "@angular/core";
import {
  Router,
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from "@angular/router";
import { BehaviorSubject, Observable, of, EMPTY, from, Subject } from "rxjs";
import { mergeMap, take, catchError, map } from "rxjs/operators";
import xml2js from "xml2js";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { ClinicService } from "../_services/clinic.service";
import { AppConfigService } from "../_services/app-config.service";
import { StorageService } from "../_services/storage.service";
@Injectable({
  providedIn: "root",
})
export class Initlist implements Resolve<any> {
  initclinik = AppConfigService.settings.clinic.name;
  initpk = AppConfigService.settings.clinic.pk;

  constructor(
    private router: Router,
    private CS: ClinicService,
    private http: HttpClient,
    private storageService: StorageService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var LocationSelected =
      this.storageService.secureStorage.getItem("locationSelected");

    var AppointmentTypeQuery =
      LocationSelected["AppointmentTypeQuery"] != ""
        ? LocationSelected["AppointmentTypeQuery"]
        : AppConfigService.settings.AppointmentTypeQuery;

    return this.CS.getservices(AppointmentTypeQuery);
    //  return {};
  }
}
