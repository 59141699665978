<main class="py-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-5">
          
          <div class="card card-body " style="min-height: 250px ;">
            <p>Dear {{Appdata['FirstName']}} {{Appdata['LastName']}},</p>
            <p>Thank you for booking an Appointment with Dr. Sri Lakshmi Srinivas Guruji.</p>
<ul class="list-group list-group-flush">
    <li class="list-group-item border-0 mb-2">
        <p class="mb-1 fs-13">Full Name </p>
        <h5 class="mb-1 fs-16 fw-bold">{{Appdata['FirstName']}} {{Appdata['LastName']}}</h5>
    </li>
    <li class="list-group-item border-0 mb-2">
        <p class="mb-1 fs-13">Mobile Phone</p>
        <h5 class="mb-1 fs-16 fw-bold">{{Appdata['PhoneNumber']}}</h5>
    </li>
    <li class="list-group-item border-0 mb-2">
        <p class="mb-1 fs-13">Email address</p>
        <h5 class="mb-1 fs-16 fw-bold">{{Appdata['EmailId']}}</h5>
    </li>
    <li class="list-group-item border-0 mb-2">
        <p class="mb-1 fs-13">Booking Type</p>
        <h5 class="mb-1 fs-16 fw-bold"> <span *ngIf="Appdata['BookingType']=='0'" class="badge bg-warning">Individual</span><span *ngIf="Appdata['BookingType']=='1'" class="badge bg-success">Family</span></h5>
    </li>
    <li class="list-group-item border-0 mb-2"  *ngIf="Appdata['BookingType']=='1'">
        <p class="mb-1 fs-13">Family Details</p>
        <h5 class="mb-1 fs-16 fw-bold">{{Appdata['FamilyDetails']}}</h5>
    </li>
    <li class="list-group-item border-0 mb-2">
        <p class="mb-1 fs-13">Appointment Location - ನೇಮಕಾತಿ ಸ್ಥಳ</p>
        <h5 class="mb-2 fs-16 fw-bold">{{Appdata['cliniddata']["Name"]}}</h5>
        <p>{{Appdata['cliniddata']["Address_Line_1"]}}<br />
            {{Appdata['cliniddata']["Suburb"]}} {{Appdata['cliniddata']["State"]}}
            {{Appdata['cliniddata']["Postal_Code"]}}</p>
    </li>
    <li class="list-group-item border-0 mb-2">
        <p class="mb-1 fs-13">Appointment date and time</p>
        <div class="d-flex align-items-center mb-2">
            <i class="bi bi-calendar3 me-2"></i>
            <h5 class="mb-0 fs-16 fw-normal" [innerHTML]="dateFormate(Appdata['starts_at'])"></h5>
        </div>
        <div class="d-flex align-items-center mb-2">
            <i class="bi bi-clock me-2"></i>
            <h5 class="mb-0 fs-16 fw-bold" [innerHTML]="timeFormate(Appdata['stime'])"></h5>
        </div>
        <div class="d-flex align-items-center mb-2">
            <i class="bi bi-hourglass-split me-2"></i>
            <h5 class="mb-0 fs-16 fw-bold" [innerHTML]="Appdata['Duration']+' minutes appointment'"></h5>
        </div>
    </li>
</ul>
</div>
</div>
</div>
</div>
</main>