import { Component, OnInit } from "@angular/core";
import {
  ActivatedRoute,
  Router,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from "@angular/router";
@Component({
  selector: "app-completed",
  templateUrl: "./completed.component.html",
  styleUrls: ["./completed.component.scss"],
})
export class CompletedComponent implements OnInit {
  Appdata: object = {};
  months: object = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    const appdata = this.route.snapshot.data["data"];
    console.log(appdata);
    this.Appdata = appdata;
  }
  dateFormate(cdate) {
    var locadate = new Date(cdate);

    return (
      "<b>" +
      locadate.getDate() +
      " " +
      this.months[locadate.getMonth()] +
      "</b>, " +
      locadate.getFullYear()
    );
  }

  timeFormate(stime) {
    let m;
    const regex = /(AM|PM)/;
    var newtimeFormate;
    if ((m = regex.exec(stime)) !== null) {
      m.forEach((match, groupIndex) => {
        newtimeFormate = stime.replace(
          regex,
          '<span class="text-warning">' + match + "</span>"
        );
      });
    }

    return newtimeFormate;
  }
}
