import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";


import { Initlist } from "./_resolver/initlist.resolve";
import { OrderstatusR } from "./_resolver/orderstatus.resolve";
import { DateAvailabel } from "./_resolver/dateaval.resolve";
import { TimeAvailabel } from "./_resolver/timeaval.resolve";
import { SuccessAppointment } from "./_resolver/success.resolve";

import { OrderstatusComponent } from "./pages/orderstatus/orderstatus.component";
import { CompletedComponent } from "./pages/completed/completed.component";
import { RequestcallbackComponent } from './pages/requestcallback/requestcallback.component';
const routes: Routes = [
  {
path:"",
loadChildren:()=>import('./pages/steps/steps.module').then(m=>m.StepsModule)
  },
 
  {
    path: "success/:pk",
    component: CompletedComponent,
    resolve: {
      data: SuccessAppointment,
    },
    data: { animation: 5}
  },
  {
    path: "order/success",
    component: OrderstatusComponent,
   resolve: {status: OrderstatusR },
    data: { animation: 6}
  },
  {
    path: "order/failed",
    component: OrderstatusComponent,
    resolve: { list: Initlist, status: OrderstatusR },
    data: { animation:7}
  },
  {
    path: "callback",
    component: RequestcallbackComponent,
   
    data: { animation:8}
  },

  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [Initlist],
})
export class AppRoutingModule {}
