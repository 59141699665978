<main class="py-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-5">
        
        <div class="card card-body d-flex  show-sm" style="min-height: 250px ;">
<div *ngIf="!AppData['FirstName']" class="w-100 flex-grow-1 d-flex align-items-center justify-content-center">
  <span class="loader-62"> </span>

</div>

<div *ngIf="AppData['FirstName']" class="flex-grow-1">
  <p>Dear {{AppData['FirstName']}} {{AppData['LastName']}},</p>
  <p>Thank you for booking an Appointment with Dr. Sri Lakshmi Srinivas Guruji.</p>
  <p>Your appointment is confirmed and your booking details along with do's,  don'ts, Appointment Location details are sent via email to your  : {{AppData['EmailId']}}</p>
  <p>If you are unable to find your  email confirmation in  normal inbox. Request to check  in your spam or junk or other mail folders.</p>
  <p>Your booking details are as below </p>
  <ul class="list-group list-group-flush">
    <li class="list-group-item border-0 mb-2">
      <p class="mb-1 fs-13">Payment Status - ಪಾವತಿ ಸ್ಥಿತಿ</p>
      <h5 class="mb-1 fs-21 fw-bold">{{PaymentStatus}}</h5>
    </li>
    <li class="list-group-item border-0 mb-2">
      <p class="mb-1 fs-13">Booking Id - ಬುಕಿಂಗ್ ಐಡಿ</p>
      <h5 class="mb-1 fs-16 fw-bold">{{AppData['ApptBookingId']}}</h5>
    </li>
    <li class="list-group-item border-0 mb-2">
      <p class="mb-1 fs-13">Fullname - ಪೂರ್ಣ ಹೆಸರು</p>
      <h5 class="mb-1 fs-16 fw-bold">{{AppData['FirstName']}} {{AppData['LastName']}}</h5>
    </li>

    <li class="list-group-item border-0 mb-2">
      <p class="mb-1 fs-13">Mobile Phone - ಮೊಬೈಲ್ ಸಂಖ್ಯೆ</p>
      <h5 class="mb-1 fs-16 fw-bold">{{AppData['PhoneNumber']}}</h5>
    </li>
    <li class="list-group-item border-0 mb-2">
      <p class="mb-1 fs-13">Email address - ಇಮೇಲ್ ಐಡಿ</p>
      <h5 class="mb-1 fs-16 fw-bold">{{AppData['EmailId']}}</h5>
    </li>
    <li class="list-group-item border-0 mb-2">
      <p class="mb-1 fs-13">Appointment date and time - ನೇಮಕಾತಿ ದಿನಾಂಕ ಮತ್ತು ಸಮಯ</p>
      <div class="d-flex align-items-center mb-2">
        <i class="bi bi-calendar3 me-2"></i>
        <h5 class="mb-0 fs-16 fw-normal" [innerHTML]="dateFormate(AppData['appdate'])"></h5>
      </div>
      <div class="d-flex align-items-center mb-2">
        <i class="bi bi-clock me-2"></i>
        <h5 class="mb-0 fs-16 fw-bold" [innerHTML]="timeFormate(AppData['stime'])"></h5>
      </div>
      <div class="d-flex align-items-center mb-2">
        <i class="bi bi-hourglass-split me-2"></i>
        <h5 class="mb-0 fs-16 fw-bold" [innerHTML]="AppData['Duration']+' minutes appointment'"></h5>
      </div>
    </li>
    <li class="list-group-item border-0 mb-2">
      <p class="mb-1 fs-13">Appointment Location - ನೇಮಕಾತಿ ಸ್ಥಳ</p>
      <div *ngIf="AppData['cliniddata']">
        <h5 class="mb-2 fs-16 fw-bold">{{AppData['cliniddata']["Name"]}}</h5>
        <p>{{AppData['cliniddata']["Address_Line_1"]}}<br />
          {{AppData['cliniddata']["Suburb"]}} {{AppData['cliniddata']["State"]}}
          {{AppData['cliniddata']["Postal_Code"]}}</p>
      </div>
      
    </li>
   
  </ul>
  <p>For any further details you can call on below numbers <a href="tel:+919964234609">+91 99642 34609</a> , <a href="tel:+919945084300">+91 99450 84300</a> or  Email us on <a href="mailto:info@ssbk.in">info@ssbk.in</a></p>
  <p>
    Kind regards, <br/>
The Team at <br/>
Dr. Sri Lakshmi Srinivas Guruji
  </p>
</div>
         
        </div>


      </div>
    </div>
    <div class="row justify-content-center" *ngIf="!AppData['FirstName']">
      <div class="col-md-6 text-center fw-bold fs-16">
        <p>Please wait while we are processing your request..</p>
        </div>
        </div>
  </div>
</main>
