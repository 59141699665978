import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpErrorResponse,
} from "@angular/common/http";

import {
  BehaviorSubject,
  Observable,
  Subject,
  throwError,
  forkJoin,
} from "rxjs";
import { mergeMap, take, catchError, map, retry } from "rxjs/operators";

import { AppConfigService } from "./app-config.service";

@Injectable({
  providedIn: "root",
})
export class ClinicService {
  apiurl = AppConfigService.settings.apiServer.apiurl;
  constructor(private http: HttpClient) {}
  getservices(AppointmentTypeQuery: string): Observable<any> {
    var url =
      this.apiurl +
      "appointment/autoservicetype/?filter=" +
      AppointmentTypeQuery;
    /*var urlP = this.apiurl + "appointment/practitioners/";*/
    const response1 = this.http.get(url);
    /*	const response2 = this.http.get(urlP);*/
    return response1;
  }

  async updateingleApp(pk, payment_status,payment_request_id) {
    var url = this.apiurl + "appointment/appstatus/";
    var formData: any = new FormData();
    formData.append("payment_id", payment_status);
    formData.append("payment_request_id", payment_request_id);
    formData.append("pkey", pk);
    return await this.http.post(url, formData).toPromise();
  }
  getsingleApp(pk): Observable<any> {
    var url = this.apiurl + "appointment/singleapp/" + pk;
  
    return this.http.get(url);
  }
}
